var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Album" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "5" } }),
                          _c("b-col", { attrs: { cols: "5" } }),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "2" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://assets.the-source.eu/frontcover/" +
                                    _vm.release.ProductDetails.ReportingCode +
                                    "/140",
                                  width: "140"
                                }
                              }),
                              _c("p", { staticClass: "mt-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.release.ProductDetails.DeliveryStatus
                                      .Description
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", [_vm._v("Kies de tags voor afspeellijst.")]),
                  _c("tag-list", { attrs: { items: _vm.tags } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }